import React from 'react';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';

import styles from './home.module.css';

export default ({ logo, heroImage, openMobileNav }) => {
  return (
    <BackgroundImage className={styles.container} fluid={heroImage.fluid}>
      <a onClick={openMobileNav} className={styles.logo}>
        <Img fluid={logo.fluid} />
      </a>
    </BackgroundImage>
  )
}