import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import SEO from '../components/seo';
import Home from '../components/home';
import Layout from '../components/layout';

class RootIndex extends React.Component {

  state = {
    mobileNavIsOpen: false
  }

  openMobileNavigation = () => {
    this.setState({ mobileNavIsOpen: true })
  }

  render() {
    const page = get(this, 'props.data.contentfulSeite');
    const logo = get(this, 'props.data.contentfulAsset');

    return (
      <Layout location={this.props.location} noFooter={true} mobileNavIsOpen={this.state.mobileNavIsOpen}>
        <div style={{ background: '#fff' }} id="page-wrap">
          <SEO description microdata />
          <Home logo={logo} heroImage={page.heroImage} openMobileNav={this.openMobileNavigation} />
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    contentfulSeite(slug: { eq: "home" }) {
      heroImage {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyContentfulFluid
        }
      }
    }
    contentfulAsset(contentful_id: { eq: "1wMLIjdoTgf4fsHCI0Q3in" }) {
      fluid(maxWidth: 700, quality: 100) {
        ...GatsbyContentfulFluid
      }
    }
  }
`
